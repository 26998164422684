import { render, staticRenderFns } from "./riskCheckRule.vue?vue&type=template&id=e13f4a16&scoped=true"
import script from "./riskCheckRule.vue?vue&type=script&lang=js"
export * from "./riskCheckRule.vue?vue&type=script&lang=js"
import style0 from "./riskCheckRule.vue?vue&type=style&index=0&id=e13f4a16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e13f4a16",
  null
  
)

export default component.exports