<template>
    <div class="dialog_section detail-content">
        <div class="detail_title" style="margin-top: 0.1rem">电动车信息</div>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">当前车速</el-col>
            <el-col :span="6">0 km/h</el-col>
            <el-col class="row_title" :span="6">SOC</el-col>
            <el-col :span="6">-- %</el-col>
        </el-row>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">车辆状态</el-col>
            <el-col :span="6"><el-tag class="tag" size="mini" type="danger">未启动</el-tag></el-col>
            <el-col class="row_title" :span="6">充电状态</el-col>
            <el-col :span="6"><el-tag class="tag" size="mini" type="danger">未充电</el-tag></el-col>
        </el-row>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">绝缘电阻</el-col>
            <el-col :span="6">-- kΩ</el-col>
            <el-col class="row_title" :span="6">电池健康状态</el-col>
            <el-col :span="6">-- %</el-col>
        </el-row>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">总电压</el-col>
            <el-col :span="6">-- V</el-col>
            <el-col class="row_title" :span="6">总电流</el-col>
            <el-col :span="6">-- A</el-col>
        </el-row>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">电池最高温度</el-col>
            <el-col :span="6">-- °C</el-col>
            <el-col class="row_title" :span="6">电池最低温度</el-col>
            <el-col :span="6">-- °C</el-col>
        </el-row>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">最高单休电压</el-col>
            <el-col :span="6">-- V</el-col>
            <el-col class="row_title" :span="6">最低单休电压</el-col>
            <el-col :span="6">-- V</el-col>
        </el-row>
        <el-row class="batteryRow">
            <el-col class="row_title" :span="6">单体压差</el-col>
            <el-col :span="6">-- V</el-col>
            <el-col class="row_title" :span="6">电源档位</el-col>
            <el-col :span="6">--</el-col>
        </el-row>
        <div class="detail_title">单体电压和温度</div>
        <div class="detail_list container">
            <div class="nums" v-for="(item, index) in nums" :key="index">
                <div class="item">
                    <div class="xuhao">{{ item }}</div>
                    <div class="txtLleft">--</div>
                    <div class="txtRight">--</div>
                    <!-- <div class="txtLleft">-- V</div>
                    <div class="txtRight">-- °C</div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceDetail',
    props: {
        detailForm: Object
    },
    data() {
        return {
            tableData: [],
            pathStr: '',
            nums: 26
        };
    },
    mounted() {
        this.pathStr = this.$route.path;
    },
    methods: {
        beforeClose(done) {
            this.$emit('beforeClose');
        }
    }
};
</script>
<style lang="less" scoped>
@import '../assets/css/table_white.css';
.detail-content {
    max-height: 490px;
}
.detail_form {
    .el-col {
        font-size: 0.18rem;
    }
}
.detail-content {
    .batteryRow {
        .row_title {
            background: rgb(250, 250, 250);
        }
        .el-col {
            padding: 6px 10px;
            border-bottom: 1px solid rgb(240, 240, 240);
            border-right: 1px solid rgb(240, 240, 240);
            position: relative;
            height: 30px;
            .tag {
                position: absolute;
                top: 4px;
            }
        }
    }
    // .batteryRow:nth-child(2n) {
    //     .el-col {
    //         border-top: 0;
    //     }
    // }
    .batteryRow {
        .el-col:nth-child(1) {
            border-left: 1px solid rgb(240, 240, 240);
        }
    }
    .batteryRow:nth-child(2) {
        .el-col {
            border-top: 1px solid rgb(240, 240, 240);
        }
    }
    .detail_list {
        height: 300px;
        .nums {
            display: inline-block;
            width: 205px;
            margin-bottom: 15px;
            // border-bottom: 1px solid red;
            .xuhao {
                display: inline-block;
                border: 1px solid #afafaf;
                border-radius: 50%;
                width: 21px;
                height: 21px;
                line-height: 20px;
                text-align: center;
            }
            .txtLleft {
                display: inline-block;
                margin: 0 5px;
            }
            .txtRight {
                display: inline-block;
                color: rgb(249, 167, 14);
            }
        }
    }
}
</style>